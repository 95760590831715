import { showAlert } from '../../util/alert.services';
const axios = require("axios");
const config = require("../../util/config");

const signInWithEmailAndPassword = async (mobile, pin) => {
  try {

    let response = await axios.post(
      config.api.adminLogin,
      { mobile, pin, user_type: "admin" },
      config.headers
    );
    return response.data.data ? response.data.data : {};
  } catch (error) {
    if (error.response) {
      showAlert(error.response.data.message, "e");
      //throw (error.error.response.data.message);
    }
  }
};

export { signInWithEmailAndPassword };
